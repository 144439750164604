import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { Fragment, useEffect, useState } from "react";
import { configPath } from "../../config";
import { Modal, Button } from "react-bootstrap";

const CheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState("");
  const [paymentIntent, setPaymentIntent] = useState({});
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }
  }, [stripe]);
  const handleAfterPayment = async (invoice, intent) => {
    const data = {
      email_address: props.data.email,
      merge_fields: {
        PAYMENT: invoice,
        PIID: intent.id,
      },
    };

    try {
      const response = await fetch(
        `${configPath.midwestApi}/update-subscription`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        }
      );
      setLoading(false);
      if (response.status === 200 && invoice === "Paid") {
        props.data.handleOpenSuccessModal(paymentIntent);
        await sendSuccessMail(props.data.email);
      } else if (invoice !== "Paid") {
        setErrorMessage("Payment transaction failed. Please try again");
      }
      if (!response.ok) {
        setLoading(false)
        response.json().then(async (errorResponse) => {
          let errorMessage;
          if(errorResponse.message && typeof errorResponse.message === 'string'){
            errorMessage = errorResponse.message
          }else if(errorResponse.message && errorResponse.message.detail && typeof errorResponse.message.detail === 'string'){
            errorMessage = errorResponse.message.detail
          }
          else if(errorResponse.message && errorResponse.message[0] && errorResponse.message[0].message){
            errorMessage = errorResponse.message[0].message;
          }else if(errorResponse.message[0].error){
            errorMessage = errorResponse.message[0].error;
          }
          if(invoice !== "Paid"){
            setErrorMessage(`Payment transaction failed. ${errorMessage}`);
          }else{
            setErrorMessage(`Important : Payment transaction success. Please contact Admin with this payment id ${intent.id}. ${errorMessage}`);
          }
        
        });
      }
    } catch (error) {
      setLoading(false);
      if (invoice !== "Paid") {
        setErrorMessage("Payment transaction failed. Please try again");
      } else {
        setErrorMessage(
          `Important : Payment transaction success. Please contact Admin with this payment id ${intent.id}`
        );
      }
    }
  };

  const sendSuccessMail = async (email) => {
    const data = {
      email: email
    };
    try{
      await fetch(`${configPath.midwestApi}/send-receipt`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
      });
    } catch(e){
      console.log(e);
    } 
  }

  const handleSubmit = async (e) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    e.preventDefault();
    setErrorMessage('')
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setLoading(true);
    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        // return_url: "http://localhost:3000/datahackathon-2024",
        payment_method_data: {
          billing_details: {
            email: props.data.email
          }
        }
      },
      redirect: "if_required",
    });
    if (result.error) {
      console.log(result.error)
      setLoading(false);
      if(result.error.type !== 'validation_error'){
        setErrorMessage(result.error.message);
      }else{
        setErrorMessage('')
      }
      // Show error to your customer (for example, payment details incomplete)
      
    } else {
      setPaymentIntent(result.paymentIntent);
      handleAfterPayment("Paid", result.paymentIntent);
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <Fragment>
      <div>
        <h2>
          Hi {props.data.fname} {props.data.lname}
        </h2>
        <h4>Ticket Amount : ${props.data.amount}</h4>
      </div>
      <form id="payment-form">
        <PaymentElement />
        <div>
          {isLoading ? (
            <button type="button" class="btn btn-primary" id="submit">
              <div class="spinner-border text-light" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </button>
          ) : (
            <button
              className="btn btn-primary"
              disabled={!stripe || !elements}
              id="submit"
              onClick={handleSubmit}
              type="button"
            >
              <span id="button-text">Pay</span>
            </button>
          )}
        </div>
        <div className="form-group text-center pay-error">
          <h4>{errorMessage}</h4>
        </div>
      </form>
    </Fragment>
  );
};

export default CheckoutForm;
