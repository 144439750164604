import React, { useState, useEffect } from 'react';

const Textslider2 = () => {
    const banTxts = [
        "Ready to build for the future?",
    
        'Because the future is <a href="#">now</a>.',
    
        'Build <a href="#">now</a>.'
    ];

    const [currentBanTxtIndex, setCurrentBanTxtIndex] = useState(0);
    const [displayText, setDisplayText] = useState('');
    const [isTyping, setIsTyping] = useState(true);

    useEffect(() => {
        const banTxt = banTxts[currentBanTxtIndex];
        let typingTimer;
    
        if (isTyping) {
          typingTimer = setTimeout(() => {
            setDisplayText(banTxt.substring(0, displayText.length + 1));
            if (displayText.length === banTxt.length) {
              setIsTyping(false);
              setTimeout(() => {
                setIsTyping(true);
                setDisplayText('');
                setCurrentBanTxtIndex((currentBanTxtIndex + 1) % banTxts.length);
              }, 1500);
            }
          }, 50);
        }
    
        return () => clearTimeout(typingTimer);
    }, [displayText, setCurrentBanTxtIndex, banTxts, isTyping]);

  return (
     <h2 className="animated animatedFadeInUp fadeInUp" dangerouslySetInnerHTML={{ __html: displayText }}></h2>
  );
};

export default Textslider2;
