import React, { Fragment, useEffect, useState } from "react";
import { Modal, Button, Carousel, Accordion } from "react-bootstrap";
import Payment from "./stripe/Payment";
import { configPath } from "../config";
import ConfettiExplosion from 'react-confetti-explosion';
import { couponCodes } from "../helpers/ticketCodes";

const ticketTypes = {
  VIP: "VIP Pass ($250)",
  GENERAL: "General Admission ($165)",
  STUDENT: "Student ($15)",
};
const ticketAmount = {
  VIP: 250,
  GENERAL: 165,
  STUDENT: 15
}

function GetTicket(props) {
  const [openTicketPopup, setOpenTicketPopup] = useState(false);
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [ticketType, setTicketType] = useState("");
  const [groupTicket, setGroupTicket] = useState(null); // yes or no
  const [noOfGroupPeople, setNoOfGroupPeople] = useState("");
  const [couponCode, setCouponCode] = useState(null); // having yes or no
  const [code, setCode] = useState("");
  const [groupMembers, setGroupMembers] = useState("");
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [steps, setSteps] = useState(1)
  const [paymentIntent, setPaymentIntent] = useState({})
  const [isExploding, setIsExploding] = useState(false);
  const [isFreeDiscount, setIsFreeDiscount] = useState(false)
  const [error, setError] = useState('');

  useEffect(() => {
    setPaymentIntent({})
  }, [])
  const handleOpenPopup = () => {
    setOpenTicketPopup(true);
  };
  const handleClosePopup = () => {
    setFname("");
    setLname("");
    setEmail("");
    setTicketType("");
    setGroupTicket(null);
    setNoOfGroupPeople("");
    setCouponCode(null);
    setCode("");
    setGroupMembers("");
    setOpenTicketPopup(false);
    setSubmitted(false)
    setLoading(false)
    setSteps(1)
    props.closeTicketPopup();
  };

  useEffect(() => {
    if (props.isOpen) {
      handleOpenPopup();
    }
  });
  const handleOptionChange = (e) => {
    setTicketType(e.target.value);
  };
  const handleHaveGroupTicket = (e) => {
    const isGroupTicket = e.currentTarget.value === "true" ? true : false;
    setGroupTicket(isGroupTicket);
  };
  const handleNoOfGroupPeople = (e) => {
    if (e.target.value > 0 && ((e.target.value) % 1 === 0)) {
      setNoOfGroupPeople(e.target.value);
    } else {
      setNoOfGroupPeople('')
    }
  };
  const handleHaveCouponCode = (e) => {
    const isHaveCoupon = e.currentTarget.value === "true" ? true : false;
    setCouponCode(isHaveCoupon);
  };
  const handleCouponCodeValue = (e) => {
    setCode(e.target.value);
    setError('')
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value.toLowerCase());
  };
  const handleFnameChange = (e) => {
    setFname(e.target.value);
  };
  const handleLnameChange = (e) => {
    setLname(e.target.value);
  };
  const handleGroupMembers = (e) => {
    setGroupMembers(e.target.value);
  };

  const closeErrorModal = () => {
    setOpenErrorModal(false);
  };
  const handleOpenErrorModal = (value) => {
    if (value && value !== '') {
      setErrorMessage(value)
    }
    setOpenErrorModal(true);
  };
  const validateAndSubmit = (event, eventName) => {
    setSubmitted(false)
    const form = document.getElementById('mc-embedded-subscribe-form');

    if (form.checkValidity()) {
      if (eventName === 'update') {
        handleUpdate(event)
      } else {
        handleSubscribe(event);
      }
    } else {
      // If form is invalid, let the browser handle displaying validation messages
      form.reportValidity();
    }
  };
  const applyCouponToAmount = () => {
    const totalAmount = getTicketAmount()
    const discountPercentage = couponCodes[code.toUpperCase()] || 0;
    const discount = (totalAmount * discountPercentage) / 100;
    const newTotalAmount = totalAmount - discount;

    return (newTotalAmount);
  };
  function getTicketAmount() {
    // Iterate through ticketTypes to find the key that matches ticketTypeString
    for (let key in ticketTypes) {
      if (ticketTypes[key] === ticketType) {
        if (groupTicket === true) {
          return noOfGroupPeople * ticketAmount[key]
        } else {
          return ticketAmount[key];
        }
      }
    }
    return null; // Handle case where ticketTypeString is not found
  }
  const handleSubscribe = async (e) => {
    e.preventDefault();
    setSubmitted(true)
    if (!email || !fname || !lname) {
      return false;
    } else {
      const data = {
        email_address: email,
        status: "subscribed",
        merge_fields: {
          FNAME: fname,
          LNAME: lname,
        },
      };
      setLoading(true)
      try {
        const response = await fetch(`${configPath.midwestApi}/subscribe`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        });

        if (response.status === 200) {
          setLoading(false)
          setSteps(2)
          setSubmitted(false)
        } else if (response.status === 202) {
          response.json().then((res) => {
            setErrorMessage(res.message);
          })
          handleOpenErrorModal();
          handleClosePopup();
        }
        if (!response.ok) {
          setLoading(false)
          response.json().then((errorResponse) => {
            let errorMessage;
            if (errorResponse.message && typeof errorResponse.message === 'string') {
              errorMessage = errorResponse.message
            } else if (errorResponse.message[0].message) {
              errorMessage = errorResponse.message[0].message;
            } else if (errorResponse.message[0].error) {
              errorMessage = errorResponse.message[0].error;
            }
            console.log("Error:", errorMessage);
            setErrorMessage(errorMessage);
            handleOpenErrorModal();
            handleClosePopup();
          });
        }
      } catch (error) {
        setLoading(false)
        if (
          error &&
          error.message &&
          error.message[0] &&
          error.message[0].error
        ) {
          setErrorMessage(error.message[0].error);
          handleOpenErrorModal();
          handleClosePopup();
        } else {
          setErrorMessage("Subscription failed. Please try again.");
          handleOpenErrorModal();
          handleClosePopup();
        }
        console.error("Error subscribing to Mailchimp:", error);
        //   setStatus('Subscription failed. Please try again.');
      }
    }

  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    setSubmitted(true)
    if (!email || !fname || !lname || !ticketType || groupTicket === null || couponCode === null) {
      return false;
    } else {
      if(code.trim() !== ''){
        const discountPercentage = couponCodes[code.toUpperCase()];
        if(discountPercentage === undefined){
          setError('Invalid Coupon Code.');
          return false;
        }else{
          setError('')
        }
      }else{
        setError('')
      }
      const data = {
        email_address: email,
        merge_fields: {
          FNAME: fname,
          LNAME: lname,
          TICKETTYPE: ticketType,
          ISGROUPED: groupTicket ? "Yes" : "No",
          MEMBERS: noOfGroupPeople !== '' ? Number(noOfGroupPeople) : 0,
          DETAIL: groupMembers,
          HAVECOUPON: couponCode ? "Yes" : "No",
          COUPONCODE: code,
          AMOUNT: `$${applyCouponToAmount()}`,
          PAYMENT: 'Unpaid'
        },
      };
      setLoading(true)
      try {
        const response = await fetch(`${configPath.midwestApi}/update-subscription`, {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        });

        if (response.status === 200) {
          if(applyCouponToAmount() === 0){
            setIsFreeDiscount(true)
            handleAfterFullDiscount()
          }else{
            setLoading(false)
            setSteps(3)
          }
        }
        if (!response.ok) {
          setLoading(false)
          response.json().then((errorResponse) => {
            console.log(errorResponse)
            let errorMessage;
            if (errorResponse.message && typeof errorResponse.message === 'string') {
              errorMessage = errorResponse.message
            } else if (errorResponse.message && errorResponse.message.detail && typeof errorResponse.message.detail === 'string') {
              errorMessage = errorResponse.message.detail
            }
            else if (errorResponse.message && errorResponse.message[0] && errorResponse.message[0].message) {
              errorMessage = errorResponse.message[0].message;
            } else if (errorResponse.message[0].error) {
              errorMessage = errorResponse.message[0].error;
            }
            console.log("Error:", errorMessage);
            setErrorMessage(errorMessage);
            handleOpenErrorModal();
            handleClosePopup();
          });
        }
      } catch (error) {
        setLoading(false)
        if (
          error &&
          error.message &&
          error.message[0] &&
          error.message[0].error
        ) {
          setErrorMessage(error.message[0].error);
          handleOpenErrorModal();
          handleClosePopup();
        } else {
          setErrorMessage("Subscription failed. Please try again.");
          handleOpenErrorModal();
          handleClosePopup();
        }
        console.error("Error subscribing to Mailchimp:", error);
        //   setStatus('Subscription failed. Please try again.');
      }
    }

  };
  const handleOpenSuccessModal = (intent) => {
    handleClosePopup()
    setPaymentIntent(intent)
    setOpenSuccessModal(true)
    setIsExploding(true)
  }
  const handleCloseSuccessModal = () => {
    setOpenSuccessModal(false)
    setIsExploding(false)
    setIsFreeDiscount(false)
  }
 
  const handleAfterFullDiscount = async () => {
    const data = {
      email_address: email,
      merge_fields: {
        PAYMENT: 'Paid',
      },
    };

    try {
      const response = await fetch(
        `${configPath.midwestApi}/update-subscription`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        }
      );
      setLoading(false)
      if (response.status === 200) {
        setOpenSuccessModal(true)
        setIsExploding(true)
        handleClosePopup()
        await sendSuccessMail(email);
      } else {
        handleClosePopup()
        setErrorMessage("You have received a 100% discount. However, the transaction failed. Please try again later, or contact the admin for assistance.");
        handleOpenErrorModal()
      }
      if (!response.ok) {
        setLoading(false)
        response.json().then((errorResponse) => {
          let errorMessage;
          if(errorResponse.message && typeof errorResponse.message === 'string'){
            errorMessage = errorResponse.message
          }else if(errorResponse.message && errorResponse.message.detail && typeof errorResponse.message.detail === 'string'){
            errorMessage = errorResponse.message.detail
          }
          else if(errorResponse.message && errorResponse.message[0] && errorResponse.message[0].message){
            errorMessage = errorResponse.message[0].message;
          }else if(errorResponse.message[0].error){
            errorMessage = errorResponse.message[0].error;
          }
          handleClosePopup();
            setErrorMessage(`You have received a 100% discount. However, the transaction failed. ${errorMessage}. Please try again later, or contact the admin for assistance. `);
            handleOpenErrorModal()
        
        });
      }
    } catch (error) {
      setLoading(false)
      handleClosePopup()
      handleOpenErrorModal()
        setErrorMessage(
          `Important : You have received a 100% discount. However, the transaction failed. Please try again later, or contact the admin for assistance.`
        );
      
    }
  };

  const sendSuccessMail = async (email) => {
    const data = {
      email: email
    };
    try{
      await fetch(`${configPath.midwestApi}/send-receipt`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
      });
    } catch(e){
      console.log(e);
    } 
  }
  
  return (
    <Fragment>
      <Modal
        show={openTicketPopup}
        onHide={(e) => handleClosePopup(e, this)}
        dialogClassName="animationDialog"
        className="asc-popup"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop='static'
      >
        <Modal.Body>
          <div id="mc_embed_signup">
            {steps === 1 ? <form
              className="validate"
              novalidate
              id="mc-embedded-subscribe-form"
            >
              <div>
                <h4>Please Provide your information</h4>
                <h2>MidWestCon 2024</h2>

                <div className="_frms">
                  <div className="mc-field-group">
                    <label>
                      First name <span class="asterisk">*</span>
                    </label>
                    <input
                      type="text"
                      name="fname"
                      class="required"
                      value={fname}
                      onChange={handleFnameChange}
                      required
                    />
                    {(submitted && !fname) ? <span class="helper_text">Mandatory</span> : ''}
                  </div>
                  <div className="mc-field-group">
                    <label for="mce-lname">
                      Last name <span class="asterisk">*</span>
                    </label>
                    <input
                      type="text"
                      name="lname"
                      class="required"
                      id="mce-lname"
                      value={lname}
                      onChange={handleLnameChange}
                      required
                    />
                    {(submitted && !lname) ? <span class="helper_text">Mandatory</span> : ''}
                  </div>
                </div>
                <div className="mc-field-group">
                  <label for="mce-EMAIL">
                    Email <span class="asterisk">*</span>
                  </label>
                  <input
                    type="email"
                    name="EMAIL"
                    class="required email"
                    id="mce-EMAIL"
                    value={email}
                    onChange={handleEmailChange}
                    required
                  />
                  {(submitted && !email) ? <span class="helper_text">Mandatory</span> : ''}
                </div>



                <div class="clear d-flex mb-3 justify-content-end">
                  {isLoading ? <button
                    type="button"
                    class="button"
                  ><div class="spinner-border text-light" role="status">
                      <span class="sr-only">Loading...</span>
                    </div></button> :
                    <button
                      type="submit"
                      class="button"
                      onClick={(e) => validateAndSubmit(e, 'subscribe')}
                    >Continue</button>}
                </div>
              </div>
            </form> : ''}
            {steps === 2 ? <form
              className="validate"
              novalidate
              id="mc-embedded-subscribe-form"
            >
              <div>
                <h4>Please Provide your information</h4>
                <h2>MidWestCon 2024</h2>
                <div className="pop_scroll">
                <div className="mc-field-group">
                  <label className="mb-3">
                    What type of ticket do you want? <span class="asterisk">*</span>

                  </label>
                  <div>
                    <p>General Admission:</p>
                    <p>
                      Full access to the conference from September 4th to 6th (3
                      days). Complimentary conference workshops. Free entry to
                      the "Future of Data Hackathon."
                    </p>

                    <p>VIP Pass:</p>
                    <p>
                      Upgrade to the VIP Pass and enjoy all the benefits of
                      General Admission, plus exclusive perks:
                    </p>
                    <div className="frm_blk">
                      <ul>
                        <li>Early access to MidwestCon workshops.</li>
                        <li>VIP seating for keynote sessions.</li>
                        <li>Access to the exclusive VIP lounge.</li>
                        <li>Official conference T-shirt & Swag Bag.</li>
                      </ul>
                      <ul>
                      <li>Entry to the VIP Disruption Mixer with speakers.</li>
                        <li>A special MidwestCon 2024 VIP welcome gift.</li>
                        <li>
                          Personalized networking one-on-one matching with
                          potential leads, jobs, or partners.
                        </li>
                        <li>
                          Admission to exclusive after-conference networking
                          events.
                        </li>
                      </ul>
                    </div>
                    <p>Important Note:</p>
                    <p>
                      Tickets are non-refundable after purchase and exclude a
                      5.9% ticketing fee.
                    </p>
                    {(submitted && !ticketType) ? <span class="mandatory mb-2"> *Required</span> : ''}
                  </div>
                  <div className="_frm">
                    <div>
                      <label>
                        <input
                          type="checkbox"
                          value={ticketTypes.VIP}
                          checked={ticketType === ticketTypes.VIP}
                          onChange={handleOptionChange}
                        />
                        <span>{ticketTypes.VIP}</span>
                      </label>
                    </div>
                    <div>
                      <label>
                        <input
                          type="checkbox"
                          value={ticketTypes.GENERAL}
                          checked={ticketType === ticketTypes.GENERAL}
                          onChange={handleOptionChange}
                        />
                        <span>{ticketTypes.GENERAL}</span>
                      </label>
                    </div>
                    <div>
                      <label>
                        <input
                          type="checkbox"
                          value={ticketTypes.STUDENT}
                          checked={ticketType === ticketTypes.STUDENT}
                          onChange={handleOptionChange}
                        />
                        <span>{ticketTypes.STUDENT}</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="mc-field-group">
                  <label className="mb-3">
                    Do you want to get tickets for your group?
                    <span class="asterisk">*</span>
                    {(submitted && groupTicket === null) ? <span class="mandatory"> *Required</span> : ''}
                  </label>
                  <div className="_frm2">
                    <div>
                      <label>
                        <input
                          type="checkbox"
                          value="true"
                          checked={groupTicket === true}
                          onChange={handleHaveGroupTicket}
                          name="groupTicket"
                        />
                        <span>Yes</span>
                      </label>
                    </div>
                    <div>
                      <label>
                        <input
                          type="checkbox"
                          value="falses"
                          checked={groupTicket === false}
                          onChange={handleHaveGroupTicket}
                          name="groupTicket"
                        />
                        <span>No</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="mc-field-group" hidden={!groupTicket}>
                  <label for="mce-noOfGroupPeople">
                    How many people are you buying tickets for?{" "}
                    <span class="asterisk">*</span>
                  </label>
                  <input
                    type="text"
                    value={noOfGroupPeople}
                    name="noOfGroupPeople"
                    class="required"
                    id="mce-noOfGroupPeople"
                    onChange={handleNoOfGroupPeople}
                    required={groupTicket === true}
                  />
                </div>
                <div className="mc-field-group" hidden={noOfGroupPeople === ""}>
                  <label for="mce-members">
                    Please enter the First Name, Last Name and Email of your
                    group members.
                    <br />
                    Seperate Each with a common : John Smith J@co.com, Jane
                    Smith Jane@c.com
                  </label>
                  <input
                    type="text"
                    value={groupMembers}
                    name="groupMembers"
                    class="required"
                    id="mce-members"
                    onChange={handleGroupMembers}
                  />
                </div>
                <div className="mc-field-group">
                  <label className="mb-3">
                    Do you have a coupon code?<span class="asterisk">*</span>
                    {(submitted && couponCode === null) ? <span class="mandatory"> *Required</span> : ''}
                  </label>
                  <div className="_frm2">
                    <div>
                      <label>
                        <input
                          type="checkbox"
                          value="true"
                          checked={couponCode === true}
                          onChange={handleHaveCouponCode}
                          name="couponCode"
                        />
                        <span>Yes</span>
                      </label>
                    </div>
                    <div>
                      <label>
                        <input
                          type="checkbox"
                          value="falses"
                          checked={couponCode === false}
                          onChange={handleHaveCouponCode}
                          name="couponCode"
                        />
                        <span>No</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="mc-field-group" hidden={!couponCode}>
                  <label for="mce-code">
                    That's great! Please type your code.
                    <span class="asterisk">*</span>
                  </label>
                  <input
                    type="text"
                    value={code}
                    name="code"
                    class="required"
                    id="mce-code"
                    onChange={handleCouponCodeValue}
                    required={couponCode}
                  />
                   {error && <span class="mandatory">{error}</span>}
                </div>
                </div>


                <div class="clear d-flex mb-3 justify-content-end">
                  {isLoading ? <button
                    type="button"
                    class="button"
                  ><div class="spinner-border text-light" role="status">
                      <span class="sr-only">Loading...</span>
                    </div></button> :
                    <button
                      type="submit"
                      class="button"
                      onClick={(e) => validateAndSubmit(e, 'update')}
                    >Continue</button>}
                </div>
              </div>
            </form> : ''}
            {(steps === 3) ? 
              <Payment
                email={email}
                handleClosePopup={handleClosePopup}
                fname={fname}
                lname={lname}
                amount={applyCouponToAmount()}
                handleOpenSuccessModal={(value) => handleOpenSuccessModal(value)}
                handleOpenErrorModal={(value) => handleOpenErrorModal(value)}
              />
              : ''}

            <Button className="clsbtn" onClick={handleClosePopup}>
              X
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={openErrorModal}
        onHide={(e) => closeErrorModal(e, this)}
        size="lg"
        className="asc-popup"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div id="mc_embed_signup">
            <div className="form-group text-center">
              <h4 className="transform-none">{errorMessage}</h4>
            </div>
            <Button className="clsbtn" onClick={closeErrorModal}>
              X
            </Button>
          </div>
        </Modal.Body>
      </Modal>


      <Modal
        show={openSuccessModal}
        onHide={(e) => handleCloseSuccessModal(e, this)}
        size="lg"
        className="asc-popup"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>

          <div id="mc_embed_signup">

            {isExploding && (
              <div className="d-flex align-items-center justify-content-center w-100">
                <ConfettiExplosion particleCount={75} duration={5000} particleSize={10} onComplete={() => setIsExploding(false)} zIndex={99999} />
              </div>
            )}

            <div className="form-group my-5 text-center">
              <img src={'./assets/img/ticket.png'} alt="ticket" width={200} />
              {isFreeDiscount ? <h4 class="display-5 d-block my-3">You have received a 100% discount</h4> : ''}
              <h4 class="display-5 d-block my-3">Thank you for buying Tickets.</h4>
              <h4>{paymentIntent.status}</h4>
            </div>
            <Button className="clsbtn clsbtn2" onClick={handleCloseSuccessModal}>
              X
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
}

export { GetTicket };
