import { midConstants } from "../../constants";

const initialState = {
    allevents : [],
    getAlleventsFailed : [],
    alleventsList : [],
    eventsListFailed : []
}
export function midReducer(state = initialState, action) {
    switch (action.type) {
        case midConstants.GET_ALL_EVENTS_GALLERY:
            return {
                ...state,
                allevents : action.allevents
            }
        case midConstants.GET_ALL_EVENTS_GALLERY_FAILED:
            return {
                ...state,
                getAlleventsFailed : action.getAlleventsFailed
            }
        case midConstants.GET_ALL_EVENTS:
            return {
                ...state,
                alleventsList : action.alleventsList
            }
        case midConstants.GET_EVENTS_FAILED:
            return {
                ...state,
                eventsListFailed : action.eventsListFailed
            }
            default:
            return state
    }
}