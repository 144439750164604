import React, { useState, useEffect } from 'react';

const Textslider = () => {
  const paragraphs = [
    "With Cincinnati's <span class='clrfffbf9 fst-italic'>unmatched</span> expertise thanks to industry giants like <span class='clr38b6ff'>Procter & Gamble</span> and <span class='clr38b6ff'> Kroger</span>, the region stands as <span class='clrfffbf9 fst-italic'>a beacon of potential.</span>",

    'The <span class="clrff4b4b">University of Cincinnati‘s</span> research prowess and <span class="clrfffbf9 fst-italic">forward-looking</span> "Digital Futures" initiative, makes Ohio a <span class="clrfffbf9 fst-italic">fertile ground for creative development.</span>',

    'This <span class="clrfffbf9 fst-italic">unique blend</span> of established companies and innovative startups makes Cincinnati not just a location, but<span class="clrfffbf9 fst-italic"> the heart beat of a movement</span> towards socially impactful technology.'
  ];

  const [currentParagraphIndex, setCurrentParagraphIndex] = useState(0);
  const [displayText, setDisplayText] = useState('');
  const [isTyping, setIsTyping] = useState(true);

  useEffect(() => {
    const paragraph = paragraphs[currentParagraphIndex];
    let typingTimer;

    if (isTyping) {
      typingTimer = setTimeout(() => {
        setDisplayText(paragraph.substring(0, displayText.length + 1));
        if (displayText.length === paragraph.length) {
          setIsTyping(false);
          setTimeout(() => {
            setIsTyping(true);
            setDisplayText('');
            setCurrentParagraphIndex((currentParagraphIndex + 1) % paragraphs.length);
          }, 1000);
        }
      }, 40);
    }

    return () => clearTimeout(typingTimer);
  }, [displayText, currentParagraphIndex, paragraphs, isTyping]);

  return (
    <div className="text-slider">
      <h3 className="pfdf40 pfdf26 mb-0" dangerouslySetInnerHTML={{ __html: displayText }}></h3>
    </div>
  );
};

export default Textslider;
