import React, { useState } from 'react';

const SpeakerLineup = ({ itemsToShowInitially, itemsPerLoad, items }) => {
  const [visibleItems, setVisibleItems] = useState(itemsToShowInitially);

  const handleLoadMore = () => {
    setVisibleItems(prevVisibleItems => prevVisibleItems + itemsPerLoad);
  };

  return (
    <div className="list">
      {items.slice(0, visibleItems).map((item, index) => (
        <div key={index} className="item">
          <a href={item.link} target="_blank" className="psitem">
            <div className="psitemimg">
              <img src={item.image} alt="" className="imgcenterimg" />
            </div>
            <h4 className="clrfffbf9 fst-italic text-center">
              {item.name}<br /><br />
              {item.role}<br />
              {item.company}
            </h4>
          </a>
        </div>
      ))}
      {visibleItems < items.length && (
        <button style={{ fontSize: 20 }} className="btn btn-primary btn-custom animated animatedFadeInUp fadeInUp" onClick={handleLoadMore}>
            <span data-aos="fade-up" data-aos-duration="1500">see all speakers</span>
        </button>
      )}
    </div>
  );
};

export default SpeakerLineup;
