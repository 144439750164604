import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import CheckoutForm from './CheckoutForm';
import { configPath } from '../../config';
import './Payment.css';
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(configPath.stripe_pkey);

export default function Payment(props) {
  const [clientSecret, setClientSecret] = useState("");
  function dollarsToCents(dollars) {
    return dollars * 100;
  }

  useEffect(() => {
   // Create PaymentIntent as soon as the page loads
fetch(`${configPath.midwestApi}/create-payment-intent`, {
  method: 'POST',
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify({ amount: dollarsToCents(props.amount), email: props.email }), // Amount in cents
})
  .then((res) => {
    if (!res.ok) {
      setClientSecret('')
      props.handleOpenErrorModal(`Network response was not ok: ${res.statusText}`)
      props.handleClosePopup();
    }
    return res.json().catch((error) => {
      setClientSecret('')
      props.handleOpenErrorModal('Error parsing JSON: ' + error.message)
      props.handleClosePopup();
    });
  })
  .then((data) => {
    setClientSecret(data.clientSecret);
  })
  .catch((error) => {
    console.error('Error:', error);
    // Handle the error appropriately in your application
    // For example, you could set an error message in state
    // setErrorMessage('Failed to create PaymentIntent');
  });
  }, []);

  const appearance = {
    theme: 'stripe',
    rules: {
      '.Label': {
        color: '#ffccae',
        margin: '0 0 10px',
      }
    }
  };

  const options = {
    clientSecret,
    appearance,
  };

  return (
    clientSecret ? <Elements stripe={stripePromise} options={options}>
      <CheckoutForm data={props} />
    </Elements> : <div class="spinner-border text-light" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  );
};